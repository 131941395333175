import { useMemo } from 'react';
import withErrorBoundary, {
  DefaultErrorComponent,
  ErrorComponentType,
} from 'src/lib/HOC/ErrorBoundary/withErrorBoundary';
import Logger from 'src/lib/Utils/Logger';

const defaultErrorProps = {
  defaultTitleContent: 'Error',
  defaultMessageContent: 'Component failed to load.',
};

type UseResolver = <ResolverFn extends (...args: any) => any>(args: {
  resolver: ResolverFn;
  props: any;
  ErrorComponent?: ErrorComponentType;
}) =>
  | {
      content: ReturnType<ResolverFn>;
      error: undefined;
      ErrorComponent: null;
    }
  | {
      content: undefined;
      error: Error;
      ErrorComponent: JSX.Element;
    };

export const useResolver: UseResolver = ({
  resolver,
  props,
  ErrorComponent = DefaultErrorComponent,
}) => {
  const value = useMemo(() => {
    try {
      const propsValue = resolver(props);

      return { propsValue };
    } catch (error: any) {
      Logger(error, { message: 'Content Resolver Error' });

      return { error };
    }
  }, [props]);

  if ('propsValue' in value && value.propsValue) {
    return {
      content: value.propsValue,
      ErrorComponent: null,
      error: undefined,
    };
  } else {
    const DefaultError = <ErrorComponent error={value.error} {...defaultErrorProps} />;

    return {
      content: undefined,
      error: value.error,
      ErrorComponent: DefaultError,
    };
  }
};

type WithResolver = <ResolverFn extends (...args: any) => any>(
  WrappedComponent: React.FC<ReturnType<ResolverFn>>,
  args: { resolver: ResolverFn; ResolverError?: ErrorComponentType }
) => React.FC<Parameters<ResolverFn>[0]>;

export const withResolver: WithResolver = (WrappedComponent, { resolver, ResolverError }) => {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const Component = (props: any) => {
    const { ErrorComponent, content } = useResolver({
      resolver,
      props,
      ErrorComponent: ResolverError,
    });
    if (ErrorComponent) return ErrorComponent;

    return <WrappedComponent {...content} />;
  };

  Component.displayName = `withResolver(${displayName})`;
  return Component;
};

/** Provides default handling for rendering errors / sitecore data discrepancies  */
const withComponentSetup = <ResolverFn extends (...args: any) => any>({
  resolver,
  Component,
  ResolverError,
  UnhandledError,
}: {
  /** a function to "resolve" the shape of props for the Component. (safe to throw errors – they are caught) */
  resolver: ResolverFn;
  /** the component to wrap */
  Component: React.FC<ReturnType<ResolverFn>>;
  /** custom error component to display when resolver() throws */
  ResolverError?: ErrorComponentType;
  /** custom error component to display if ErrorBoundary triggers and catches unhandled rendering error */
  UnhandledError?: ErrorComponentType;
}) => {
  const Resolver = withResolver<ResolverFn>(Component, {
    resolver,
    ResolverError,
  });
  const Boundary = withErrorBoundary(Resolver, UnhandledError);
  return Boundary;
};

export { withComponentSetup };
